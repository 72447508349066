import React from 'react';
import { NavLink, BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import {
  InstantSearch,
  Hits,
  Pagination,
  Configure,
  RefinementList,
  connectSearchBox,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import withURLSync from './URLSync';
import './App.css';
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";


const searchClient = algoliasearch(
  'L15H9YP244',
  'f2d1709ebd83a1cc45c33666e51b8a94',
);

// const index = searchClient.initIndex('daos');

// const searchClient = algoliasearch(
//   'latency',
//   '6be0576ff61c053d5f9a3225e2a90f76'
// );

// const SetSearchState = ({searchState, setSearchState, name}) => {
//   if (searchState['refinementList'] === undefined){
//     setSearchState({
//       query: '',
//       refinementList: {
//         category: [name],
//       },
//     });
//     return <div></div>
//   }
//   else{
//     searchState['query'] = '';
//     searchState['refinementList']['category'] = [name];
//   }
// }

const App = props => { 
  const [searchState, setSearchState] = React.useState({});
  // const [navbarOpen, setNavbarOpen] = React.useState(false)
  
  return <InstantSearch
    searchClient={searchClient}
    indexName="daos"
    searchState={searchState}
    createURL={props.createURL}
    onSearchStateChange={setSearchState}
     urlSync = "true"
  > 
  <Router> 
    {/* <body> */}
    <Configure hitsPerPage={15} />
    <body>
    {/* <div className="container"> */}
    <nav id="my-side-bar" className = "navbar">
    <ul className="navbar-nav">
    <li><a className="side-logo" href = "./"> <img className = "main-logo" alt="nonsense" src="dao-logo.png" width="100%" ></img> </a></li>
    {/* <li><button>test</button></li>
    <li> <a href = "./"> <img class = "main-logo" alt="nonsense" src="dao-logo.png" width="100%" ></img> </a></li> */}
    {/* <li> <span><SearchBox translations={{placeholder: 'Find a Delicious DAO...',}}/></span></li> */}
    <li> <div className="close-button-container"><button className="close-side-menu" onClick={() => closeNav()}><MdClose size='10x'/></button>
</div></li>
    <li> <div className="search-box-container"><div/><SearchBox/><div/></div></li>
    <button id="button_submit_dao_side" className="submit-dao-side"type="website-link"onClick={() => window.open("https://3vpso54u8mv.typeform.com/to/AJ0dSn2h", "_blank")}>+ Submit a DAO</button>
    <li className="separator" /><li>
     
    {/* <CustomSearchBox setSearchState = {setSearchState} searchState = {searchState} history={history}/> */}
    {/* <CustomSearchBox setSearchState = {setSearchState} searchState = {searchState}/> */}
      <ul className="categories-menu">
      <li style={{"margin-bottom":"20px"}}> <NavLink  className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'', setSearchState:setSearchState}))}} to = "/" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Home </NavLink></li>
      <li> <NavLink id="menu_item_collector" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Collector', setSearchState:setSearchState}))}} to = "/Collector" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Collector </NavLink> </li>
      <li> <NavLink id="menu_item_culture" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Culture', setSearchState:setSearchState}))}} to = "/Culture" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Culture </NavLink> </li>
      <li> <NavLink id="menu_item_dao_platform" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'DAO Platform', setSearchState:setSearchState}))}} to = "/DAO-Platform" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>DAO Platforms</NavLink> </li>
      <li> <NavLink id="menu_item_defi" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'DeFi', setSearchState:setSearchState}))}} to = "/DeFi" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>DeFi </NavLink> </li>
      <li> <NavLink id="menu_item_grants" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Grants', setSearchState:setSearchState}))}} to = "/Grants" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Grants </NavLink> </li>
      <li> <NavLink id="menu_item_investments" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Investment', setSearchState:setSearchState}))}} to = "/Investment" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Investment </NavLink> </li>
      <li> <NavLink id="menu_item_media" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Media', setSearchState:setSearchState}))}} to = "/Media" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Media </NavLink> </li>
      <li> <NavLink id="menu_item_service" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Service', setSearchState:setSearchState}))}} to = "/Service" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Service </NavLink> </li>
      <li> <NavLink id="menu_item_social" className = "category-link" onClick = {() => {setCategoryDel(({nameOfCategory:'Social', setSearchState:setSearchState}))}} to = "/Social" exact activeClassName="selected" isActive={(match, location) => checkActive({searchState:searchState, match:match})}>Social </NavLink> </li>
      </ul>
      <Route path="/" exact render= {() => {setCategory({nameOfCategory:'',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Search" exact render= {() => {setCategory({nameOfCategory:'',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Collector" exact render= {() => {setCategory({nameOfCategory:'Collector',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Culture" exact render= {() => {setCategory({nameOfCategory:'Culture',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/DAO-Platform" exact render= {() => {setCategory({nameOfCategory:'DAO Platform',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/DeFi" exact render= {() => {setCategory({nameOfCategory:'DeFi',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Grants" exact render= {() => {setCategory({nameOfCategory:'Grants',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Investment" exact render= {() => {setCategory({nameOfCategory:'Investment',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Media" exact render= {() => {setCategory({nameOfCategory:'Media',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Service" exact render= {() => {setCategory({nameOfCategory:'Service',searchState:searchState, setSearchState:setSearchState})}} />
      <Route path="/Social" exact render= {() => {setCategory({nameOfCategory:'Social',searchState:searchState, setSearchState:setSearchState})}} />    
    </li>
    <li> <RefinementList className="do-not-show" attribute='category' /> </li>
    <li>
    
    </li>
    <li className="separator" ></li>
    <li>
      <ul className="aboutUsBox">
        <li> <a id="link_who_made_this" className="about-us-element" target='_blank' rel="noopener noreferrer" href = "https://catapultlabs.notion.site/We-re-Greg-Rav-6b6cd9ddaa0748ec82941a0c63a5cb2b"> who made this? </a></li>
        <li> <a id="link_road_map_link" className="about-us-element" target='_blank' rel="noopener noreferrer" href = "https://catapultlabs.notion.site/d6211c10b3004b6584b15bc52ee0cde5?v=0d03d9a2d5fb458fb584e656f5529f65"> our roadmap </a></li>
        <li> <a id="link_twitter"className="about-us-element" target='_blank' rel="noopener noreferrer" href = "https://twitter.com/wearedaolicious"> @wearedaolicious</a></li>
        <li style={{"margin-top":"20px"}}> <a id = "link_report_bug" className="about-us-element" target='_blank' rel="noopener noreferrer" href = " https://3vpso54u8mv.typeform.com/to/KzgFEwnj "> report a bug </a></li>
        <li> <a id = "link_tcs" className="about-us-element" target='_blank' rel="noopener noreferrer" href = "https://catapultlabs.notion.site/T-Cs-50b6c87506cc488eba8cf377c8c32f25"> t&#38;cs</a></li>
      </ul>
    </li>
    </ul>
  </nav>
    <MobileTopBar/>
    <div className="top-bar">
    <button id="button_submit_dao_top" className="submit-dao"type="website-link"onClick={() => window.open("https://3vpso54u8mv.typeform.com/to/AJ0dSn2h", "_blank")}>+ Submit a DAO</button>
      {/* <div></div> */}
      <div className="top-comment">
      {/* <Route path="/" exact >Search for a DAO or Pick a Category!</Route> */}
      <Route path="/Collector"  exact >Gota catch em all! NFTs I mean…</Route>
      <Route path="/Culture" exact >Communities for Music, Art, Gaming, and All Things Cultural.</Route>
      <Route path="/DAO-Platform" exact >Make and Manage a DAO!</Route>
      <Route path="/DeFi" exact >Gordon Gekko meets Vitalik Buterin!</Route>
      <Route path="/Grants" exact >Pitch an Idea and Get Funded by the Community!</Route>
      <Route path="/Investment" exact >Join a Community and Invest Together!</Route>
      <Route path="/Media" exact >Communities Keeping us all Informed.</Route>
      <Route path="/Service" exact >Use your Skills and Work with Like-Minded People!</Route>
      <Route path="/Social" exact >Meet your On-Chain Tribe!</Route>  

      </div>
    </div>
    {/* <TopBar/> */}
    <Route path="/" exact > <Homepage /> </Route>  
    <Route path={["/Search", "/Collector","/Culture","/DAO-Platform","/DeFi","/Grants","/Investment","/Media","/Service","/Social"]}exact > <Results /></Route>
    
    {/* </body> */}
    </body>
    </Router> 
  
  </InstantSearch>
  }

  const Homepage = () => {
    return <main>
      <div className="homepage">
      <div className="main-homepage">
        <h1>
          Discover the most vibrant communities in Web3!
        </h1>
        <div id="opening-paragraph">
        <b>The future of investing, work and play is already happening in DAOs. We've curated over <span className="extra-bold">160</span> of them to help you find your home and make your mark! </b>
        </div>  
          <br/>
          <br/>
          <i>Explore DAOs using the categories in the menu, or search for specific DAOs.</i>
      </div>
      <div className="main-homepage" >
      <h3 style={{"margin-botom":"-2px"}}>
          Get our weekly daolicious digest for all things DAO!
      </h3>
      <RevueSignUp/>
      </div>
      <div className="main-homepage">
      <h3>
          If you're asking yourself "What's a DAO!?" <span role="img" aria-label="exploding head">🤯</span>
      </h3>
      DAOs are communities owned by their members. Check out our DAO101 <span role="img" aria-label="exploding head">👉</span> <a className="about-us-element" id="link_whats_a_dao" target='_blank' rel="noopener noreferrer" href = " https://catapultlabs.notion.site/What-s-a-DAO-35a79fc2ffba41c2ad4e6bc0dc16a254"> <b>here</b></a>!
      </div>

      
    </div>
    
    </main>
  }

  const RevueSignUp = () => {
    return <div id="revue-embed">
        <form action="https://www.getrevue.co/profile/daolicious/add_subscriber" method="post" id="revue-form" name="revue-form"  target="_blank">
        {/* <div className="revue-form-group"> */}
          {/* <label for="member_email">Email address</label> */}
          <input className="revue-form-field" placeholder=" Your email address..." type="email" name="member[email]" id="member_email"/>
        {/* </div> */}
        <div></div>
        <div className="revue-form-actions">
          <button className="home-subscribe-button" type="submit" value="Subscribe" name="member[subscribe]" id="member_submit">Sign me up!</button>
        </div>
        {/* <div class="revue-form-footer">By subscribing, you agree with Revue’s <a target="_blank" href="https://www.getrevue.co/terms">Terms of Service</a> and <a target="_blank" href="https://www.getrevue.co/privacy">Privacy Policy</a>.</div> */}
        </form>
      </div>  
  }

  function openNav() {
    document.getElementById("my-side-bar").style.width = "60%";
    // document.getElementById("main").style.marginLeft = "250px";
  }
  
  /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
  function closeNav() {
    document.getElementById("my-side-bar").style.width = "0";
  }

  // const TopBar = () => {
  //   return <div className="top-bar">
  //     {/* <div></div>
  //     <div></div> */}
  //     {/* <a href = "./" className="top-bar-category"> Hello! </a> */}
  //     <button className="submit-dao"type="website-link" >+ Submit a DAO</button>
  //     {/* <div></div> */}
  //   </div>
  // }

  const MobileTopBar = () => {
    return <div className="mobile-top-bar">
      <div className="logo-button-top"> 
            <button className="menu-button-top" onClick={() => openNav()}><FiMenu size='10x'/></button>
            <a href = "./"> <img className = "main-logo-top" alt="nonsense" src="dao-logo.png"></img> </a>
            <div></div>
      </div>
      <div className="top-search-container">
        <div></div>
        <SearchBox/>
        <div></div>
      </div>
    </div>
  }

  const setCategory = ({nameOfCategory, searchState, setSearchState}) => {
    if (searchState['refinementList'] === undefined 
    || (searchState['refinementList'])['category'] ===undefined
    || !searchState['refinementList']['category'].includes(nameOfCategory)){
      setSearchState({
        query: searchState['query'],
        refinementList: {
          category: [nameOfCategory],
        },
      });
    }
  }
  const setCategoryDel = ({nameOfCategory, setSearchState}) => {
      setSearchState({
        query: '',
        refinementList: {
          category: [nameOfCategory],
        },
      });
  }

  const checkActive = ({searchState, match}) => {
    if (!match) return false;
    if (searchState === undefined || searchState['query'] === undefined || searchState['query'] === '') return true;

  }

const SearchBox = connectSearchBox(({ currentRefinement, refine}) => {
  const history = useHistory();
  
  return <input
    type="text"
    className="test"
    style={{"width":"80%", "height":"100%", "border": "2px solid black"}}
    placeholder = ' Find a Delicious DAO...'
    value={currentRefinement}
    onSubmit={event => {
      
    //   event.preventDefault();
    //   setCategory({nameOfCategory:'', searchState:searchState, setSearchState:setSearchState});
     }}
    onChange={e => {
      refine(e.target.value)
      history.push('/Search')
    }}
      // setCategory({nameOfCategory:'', searchState:searchState, setSearchState:setSearchState});
       
      // refine(e.target.value)}}
    // onChange={e => { 
    //   return refine(e.target.value)}}
    autoComplete="off"
  />}
      )
     
const Hit = ({hit}) => 
    <div className="hit">
       <div className="hit-left">
        <div className="image-object"
          style={{ backgroundImage: `url(twitter-pictures/${hit.twitterImageFileName})` }}
          />
      </div>  
      <div className="hit-content">
      <div className="hitDescription">
      <h2 className="hit-title">
          {hit.name}
          </h2>
          {hit.overview}
      </div>
      <div className="hit-links">
      {/* <button type="website-link" onClick={() => window.document.location=hit.website} >Discover</button> */}

      <button id={`button_discover_${hit.name}`} className="discover-button" type="website-link" onClick={() => window.open(hit.website, "_blank")} >Discover</button>



      {/* <a className="discover-link" href= {hit.website} target='_blank' rel="noopener noreferrer"> Discover &rarr;</a> */}
      <a href= {hit.twitter} target='_blank' id={`link_twitter_${hit.name}`} rel="noopener noreferrer"> <img className = "twitter-button" alt="nonsense" src="twitter.png"  ></img> </a>
      {/* <a href = {hit.discordTelegram}> <img class = "discord-button" src="Discord-Logo-Color.png" height="30px" ></img> </a>  */}
      </div>
      </div>
    </div>

// const Hit = hit => {
//   const { image, rating, year, genre } = hit.hit;
//   return (
//     <div className="hit media">
//       {/* <div className="media-left">
//         <div
//           className="media-object"
//           style={{ backgroundImage: `url(${image})` }}
//         />
//       </div> */}
//       <div className="media-body">
//         <h4 className="media-heading">
//           <Highlight attribute="title" hit={hit.hit} />
//           <Stars rating={rating} />
//         </h4>
//         <p className="year">{year}</p>
//         <Genres genres={genre} />
//       </div>
//     </div>
//   );
// };

// 
// const MenuRoute = connectSearchBox(({ refine: setQuery }) =>
//   connectRefinementList(({ refine, currentRefinement, setQuery }) => {
//     return (
//       <Route 
//       exact path = "/DeFi"
//       render={() => {
//           if (currentRefinement.includes('Apple')) {
//             refine(currentRefinement.filter((el) => el !== 'Apple'));
//           } 
          
//           else {
//             setQuery('');
//             refine([...currentRefinement, 'Apple']);
//           }
//         }}
//       >
//         toggle "Apple"
//       </Route>
//     );
//   })({ setQuery })
// );


const Results = connectSearchBox(() => (
    <main>
    <div id="stats" className="text-right text-muted">
    </div>
    <div id="hits">
      <Hits hitComponent={Hit} />
    </div>
    <div id="pagination" className="text-center">
      <Pagination />
    </div>
  </main>
  // <article>
  //   <div id="stats" className="text-right text-muted">
  //   </div>
  //   <div id="hits">
  //     <Hits hitComponent={Hit} />
  //   </div>
  //   <div id="pagination" className="text-center">
  //     <Pagination />
  //   </div>
  // </article>
));

// const MenuRef = connectMenu(
//   ({ items, refine, createURL }) => {
//     const hitComponents = items.map(item => (
//       <div className={item.isRefined ? ' active' : ''} key={item.label}>
//         <a
//           className="item"
//           href={createURL(item.value)}
//           onClick={e => {
//             e.preventDefault();
//             refine(item.value);
//           }}
//         >
//         {/* {item.label} */}
//         </a>
//       </div>
//     ));

//     return <div className="nav nav-list">{hitComponents}</div>;
//   }
// );


// const RefinementListLinks = connectRefinementList(
//   ({ items, refine, createURL }) => {
//     const hitComponents = items.map(item => (
//       <div className={item.isRefined ? ' active' : ''} key={item.label}>
//         <a
//           className="item"
//           href={createURL(item.value)}
//           onClick={e => {
//             e.preventDefault();
//             refine(item.value);
//           }}
//         >
//           <span> {item.label}</span>
//         </a>
//       </div>
//     ));

//     return <div className="nav nav-list">{hitComponents}</div>;
//   }
// );

export default withURLSync(App);
